import React, { useState } from 'react';
import { Grid, Box, Button, CircularProgress } from '@mui/material';
import { Add, CheckCircle, HighlightOff } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { resetPassword } from '../../services/ApiService';
import { LocalizedDataGrid } from '../../components/table/LocalizedDataGrid';
import { IconButton } from "@mui/material";
import { Delete, EditRounded, LockReset } from '@mui/icons-material';
import { UserPhoto } from "../../components/image/UserPhoto"
import { DeleteUserDialog } from "../datagrid/DeleteUserDialog"
import { UpdateUserDialog } from "../datagrid/UpdateUserDialog"
import { ViewUserDialog } from "../datagrid/ViewUserDialog"
import { green, red } from '@mui/material/colors';

function minWidth(flex) {
    return flex * 100;
}

function UserColumns({ t, currentUser, userById, handleEditClick, handleDeleteClick, handleResetPasswordClick }) {
    return [
        {
            field: 'sn',
            headerName: t("sn"),
            filterable: false,
            disableColumnMenu: true,
            flex: 0.5,
            minWidth: minWidth(0.5)
        },
        {
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            field: 'photoBlobName',
            headerName: t("label_photo"),
            flex: 0.8,
            minWidth: minWidth(0.8),
            renderCell: (params) => <UserPhoto
                photoUrl={params.row.photoUrl}
                firstName={params.row.firstName}
                lastName={params.row.lastName}
                ownedByIconEnabled={params.row?.ownedBy.length > 0}
                verifiedIconEnabled={params.row?.password}
            ></UserPhoto>
        },
        {
            field: 'fullName',
            headerName: t("fullName"),
            flex: 2.3,
            minWidth: minWidth(2.3),
            valueGetter: (params) => {
                return params.row.firstName + " " + params.row.lastName;
            },
            renderCell: (params) => <Box sx={{ py: 1 }}>
                {params.row.firstName + " " + params.row.lastName}
                {params.row.message && <p style={{ color: 'red' }} variant="h6" component="h6">{params.row.message}</p>}
            </Box>
        },
        {
            field: 'dob',
            headerName: t("label_dob"),
            flex: 2,
            minWidth: minWidth(2),
            renderCell: (params) => (params.row.dob ?? "") + (params.row.age ? (" (" + t("text_age_value", { age: params.row.age }) + " )") : "")
        },
        {
            field: 'email',
            headerName: t("label_email"),
            flex: 1.3,
            minWidth: minWidth(1.3)
        },
        {
            field: 'phoneNumber',
            headerName: t("label_phone_number"),
            flex: 1.7,
            minWidth: minWidth(1.7)
        },
        {
            field: 'currentAddress',
            headerName: t("label_current_address"),
            flex: 1.2,
            minWidth: minWidth(1.2)
        },
        {
            field: 'occupation',
            headerName: t("label_occupation"),
            flex: 1.2,
            minWidth: minWidth(1.2)
        },
        {
            field: 'lineage',
            headerName: t("label_lineage"),
            flex: 0.8,
            minWidth: minWidth(0.8),
            renderCell: (params) => (params.row.lineage ? <CheckCircle style={{ color: green[500] }} /> : <HighlightOff style={{ color: red[500] }} />)
        },
        {
            field: 'gender',
            headerName: t("label_gender"),
            flex: 0.8,
            minWidth: minWidth(0.8),
            renderCell: (params) => params.row?.gender === "MALE" ? t("male") : (params.row?.gender === "FEMALE" ? t("female") : "")
        },
        {
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            field: 'spouse',
            headerName: t("label_spouse"),
            flex: 1.5,
            minWidth: minWidth(1.5),
            renderCell: (params) => userById(params.row.spouse)?.firstName

        },
        {
            field: 'parentName',
            headerName: t("label_parent_name"),
            flex: 1.5,
            minWidth: minWidth(1.5)
        },
        {
            field: 'action',
            headerName: t('label_edit'),
            flex: 1.2,
            minWidth: minWidth(1.2),
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            disableExport: true,
            renderCell: (params) => <>
                {(currentUser?.isFamilyAdmin || params.row.ownedBy.includes(currentUser?._id)) && <Grid container >
                    <Grid item >
                        <IconButton onClick={(e) => {
                            e.stopPropagation();
                            handleEditClick(params.row);
                        }} size="small">
                            <EditRounded style={{ fontSize: 'inherit' }} fontSize="small" />
                        </IconButton>

                    </Grid>
                    {params.row.email && !params.row.password && <Grid item>
                        <IconButton size="small" onClick={(e) => {
                            handleResetPasswordClick(params.row.email);
                            e.stopPropagation();
                        }}>
                            <LockReset style={{ fontSize: 'inherit' }} />
                        </IconButton>
                    </Grid>}
                    <Grid item >
                        <IconButton size="small" onClick={(e) => {
                            handleDeleteClick(params.row);
                            e.stopPropagation();
                        }}>
                            <Delete style={{ fontSize: 'inherit' }} color='error' />
                        </IconButton>
                    </Grid>
                </Grid>
                }
            </>
        }
    ];
}


const UsersTable = ({ setError, setLoading, setMessage, fetchProfile, currentUser, users, fetchUsers }) => {
    const { t } = useTranslation();

    const handleResetPassword = async (email) => {
        setLoading(true);
        try {
            const response = await resetPassword(email);
            setMessage(response.message);
        } catch (e) {
            console.error(e);
            setError(e.message);
        } finally {
            setLoading(false);
        }
    };


    const [viewingUser, setViewingUser] = useState(null);
    const [editingUser, setEditingUser] = useState(null);
    const [isAddingNewUser, setIsAddingNewUser] = useState(false);
    const [deleteRequestingUser, setDeleteRequestingUser] = useState(null);

    const columns = UserColumns({
        t: t,
        currentUser: currentUser,
        userById: (id) => users.find((usr) => usr._id === id),
        handleEditClick: setEditingUser,
        handleDeleteClick: setDeleteRequestingUser,
        handleResetPasswordClick: handleResetPassword
    })
    const rows = users?.map((row, index) => ({
        ...row,
        sn: index + 1,
        id: row._id
    }))

    if (users.length === 0) return <div style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Box style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress />
        </Box>
    </div>; else return (
        <Grid item xs={12}>
            <DeleteUserDialog
                user={deleteRequestingUser}
                open={deleteRequestingUser != null}
                handleClose={() => setDeleteRequestingUser(null)}
                setLoading={setLoading}
                setError={setError}
                setMessage={setMessage}
            />
            <UpdateUserDialog
                currentUser={currentUser}
                user={editingUser}
                open={editingUser != null || isAddingNewUser}
                onClose={() => { setEditingUser(null); setIsAddingNewUser(false); }}
                users={users}
                setLoading={setLoading}
                setAlertMessage={setMessage}
                setAlertError={setError}
                onUpdated={() => { fetchUsers(); fetchProfile(); }} />

            <ViewUserDialog
                user={viewingUser}
                userById={(id) => users.find((usr) => usr._id === id)}
                owners={() => viewingUser?.ownedBy?.map((ownerId) =>
                    users.find((usr) => usr._id === ownerId)
                )}
                onClose={() => setViewingUser(null)}
            />

            <Box sx={{ m: 2 }}>
                <Button onClick={() => setIsAddingNewUser(true)} size="small">
                    <Add fontSize="small" />{t("add_user")}
                </Button>
            </Box>

            <LocalizedDataGrid
                rows={rows}
                columns={columns}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            email: false,
                            parentName: false
                        },
                    },
                }}
                onRowClick={(row) => setViewingUser(row)}
            />
        </Grid>
    );
};

export default UsersTable;