import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { resetPassword } from '../../services/ApiService';

export default function ForgotPasswordDialog({ open, onClose, setLoading, setError, setMessage }) {
    const { t } = useTranslation();

    const [email, setEmail] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            const response = await resetPassword(email);
            setMessage(response.message);
            onClose();
        } catch (e) {
            console.error(e);
            setError(e.message);
        } finally {
            setLoading(false);
        }

    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{t("forgot_password_")}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t("explanation_enter_email_to_reset_password")}
                </DialogContentText>
                <TextField
                    value={email}
                    onChange={(e) =>
                        setEmail(e.target.value)
                    }
                    autoFocus
                    autoComplete="email"
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label={t("label_email")}
                    type="email"

                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{t("button_cancel")}</Button>
                <Button onClick={handleSubmit}>{t("button_reset")}</Button>
            </DialogActions>
        </Dialog>
    );
}