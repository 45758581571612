import './App.css';
import React, { useCallback, useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import SpinnerDialog from './components/spinner/SpinnerDialog';
import ErrorSnackbar from './components/snackbar/ErrorSnackbar';
import SuccessSnackbar from './components/snackbar/SuccessSnackbar';
import { getAllUsers, getMyProfile } from './services/ApiService';
import { LoggedInApp } from './LoggedIn';
import { PreLoginApp } from './PreLogin';

export function getPersistedToken() {
  return localStorage.getItem("token")
}

function App() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [token, setToken] = useState(getPersistedToken());
  const [profile, setProfile] = useState(null);

  const fetchProfile = useCallback(async () => {
    if (token) {
      localStorage.setItem("token", token);
      try {
        const data = await getMyProfile();
        setProfile(data);
      } catch (e) {
        console.error(e);
        setError(e.message);
        setToken(null);
      }
    } else {
      localStorage.removeItem("token");
      setProfile(null);
    }
  }, [token]);
  useEffect(() => { fetchProfile(); }, [fetchProfile]);

  const [users, setUsers] = useState([]);
  const fetchUsers = useCallback(async () => {
    if (token) {
      try {
        const data = await getAllUsers();
        const usersWithDob = data?.filter(user => user.dob !== null);
        const usersWithOutDob = data?.filter(user => user.dob === null);
        var combinedList = usersWithDob?.concat(usersWithOutDob);
        const lineageUsers = combinedList?.filter(user => user.lineage);
        const nonLineageUsers = combinedList?.filter(user => !user.lineage);
        combinedList = lineageUsers?.concat(nonLineageUsers);
        setUsers(combinedList);
      } catch (e) {
        console.error(e);
        setError(e.message);
      }
    }
  }, [token]);
  useEffect(() => { fetchUsers(); }, [fetchUsers]);


  async function logout() {
    setToken(null);
  }


  return <>
    <Router>
      {(token == null) ? <PreLoginApp
        setLoading={setLoading}
        setError={setError}
        setMessage={setMessage}
        onLoginSuccess={setToken}
      /> : <LoggedInApp
        setLoading={setLoading}
        setError={setError}
          setMessage={setMessage}
          profile={profile}
          fetchProfile={fetchProfile}
          logout={logout}
          users={users}
          fetchUsers={fetchUsers}
        />
      }
    </Router>
    <SpinnerDialog open={loading} />
    <ErrorSnackbar message={error} onClose={() => setError(null)} />
    <SuccessSnackbar message={message} onClose={() => setMessage(null)} />
  </>;
}

export default App;
