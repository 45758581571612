export function cropToSquare(file) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = () => {
            const minSize = Math.min(img.width, img.height);
            const cropX = (img.width - minSize) / 2;
            const cropY = (img.height - minSize) / 2;

            const canvas = document.createElement('canvas');
            canvas.width = minSize;
            canvas.height = minSize;
            const ctx = canvas.getContext('2d');

            ctx.drawImage(img, cropX, cropY, minSize, minSize, 0, 0, minSize, minSize);

            const croppedFile = dataUrlToFile(canvas.toDataURL(file.type), file.name, file.type);
            resolve(croppedFile);
        };
        img.onerror = reject;
    });
}

async function dataUrlToFile(url, filename, mimeType) {
    if (url.startsWith('data:')) {
        var arr = url.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[arr.length - 1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        var file = new File([u8arr], filename, { type: mime || mimeType });
        return Promise.resolve(file);
    }
    const res = await fetch(url);
    const buf = await res.arrayBuffer();
    return new File([buf], filename, { type: mimeType });
}