import { UploadFile } from "@mui/icons-material";
import { Checkbox, RadioGroup, FormControl, FormLabel, FormControlLabel, Grid, IconButton, InputAdornment, Radio, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import UserSelect from "./UserSelect";
import { UserPhoto } from "../image/UserPhoto"

export function UserForm({
    firstName, setFirstName,
    lastName, setLastName,
    email, setEmail,
    displayPhotoUrl, photoBlobName, handlePhotoFileChange,
    phoneNumber, setPhoneNumber,
    gender, setGender,
    dob, setDob,
    parentName, setParentName,
    occupation, setOccupation,
    currentAddress, setCurrentAddress,
    message, setMessage,
    ownedByUsersEnabled, ownedByOptions, ownedByUsers, setOwnedByUsers,
    lineage, setLineage,
    spouseOptions, spouseUser, setSpouseUser
}) {
    const { t } = useTranslation();
    return <>
        <Grid item xs={12}>
            <TextField
                label={t("label_first_name")}
                value={firstName}
                onChange={(e) =>
                    setFirstName(e.target.value)
                }
                autoFocus
                margin="dense"
                fullWidth
            />
        </Grid>
        <Grid item xs={12}>
            <TextField
                label={t("label_last_name")}
                value={lastName}
                onChange={(e) =>
                    setLastName(e.target.value)
                }
                autoFocus
                margin="dense"
                fullWidth
            />
        </Grid>
        <Grid item xs={12}>
            <TextField
                label={t("label_email")}
                value={email}
                onChange={(e) =>
                    setEmail(e.target.value)
                }
                autoFocus
                margin="dense"
                type="email"
                fullWidth
            />
        </Grid>
        <Grid item xs={12}>
            <TextField
                value={photoBlobName}
                disabled
                autoFocus
                margin="dense"
                label={t("label_photo")}
                type="url"
                fullWidth
                InputProps={{
                    startAdornment: (
                        <InputAdornment position='start'>
                            <UserPhoto
                                photoUrl={displayPhotoUrl}
                                firstName={firstName}
                                lastName={lastName}
                                ownedByIconEnabled={ownedByUsers?.length > 0}
                            ></UserPhoto>
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton component="label" >
                                <UploadFile />
                                <input
                                    onChange={(event) => {
                                        handlePhotoFileChange(event.target.files[0]);
                                    }}
                                    type="file"
                                    hidden
                                />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            >
            </TextField>
        </Grid>
        <Grid item xs={12}>
            <TextField
                value={phoneNumber}
                onChange={(e) =>
                    setPhoneNumber(e.target.value)
                }
                autoFocus
                margin="dense"
                label={t("label_phone_number")}
                type="phone"
                fullWidth
            />
        </Grid>
        <Grid item xs={12}>
            <FormControl>
                <FormLabel>{t("label_gender")}</FormLabel>
                <RadioGroup row value={gender} onChange={(event) => {
                    setGender(event.target.value);
                }}>
                    <FormControlLabel value="MALE" control={<Radio />} label={t("male")} />
                    <FormControlLabel value="FEMALE" control={<Radio />} label={t("female")} />
                </RadioGroup>
            </FormControl>
        </Grid>

        <Grid item xs={12}>
            <TextField
                value={dob}
                placeholder='YYYY-MM-DD'
                onChange={(e) =>
                    setDob(e.target.value)
                }
                autoFocus
                margin="dense"
                label={t("label_dob")}
                fullWidth
            />
        </Grid>
        <Grid item xs={12}>
            <TextField
                value={parentName}
                onChange={(e) =>
                    setParentName(e.target.value)
                }
                autoFocus
                margin="dense"
                label={t("label_parent_name")}
                type="text"
                fullWidth
            />
        </Grid>
        <Grid item xs={12}>
            <TextField
                value={occupation}
                onChange={(e) =>
                    setOccupation(e.target.value)
                }
                autoFocus
                margin="dense"
                label={t("label_occupation")}
                type="text"
                fullWidth
            />
        </Grid>
        <Grid item xs={12}>
            <TextField
                value={currentAddress}
                onChange={(e) =>
                    setCurrentAddress(e.target.value)
                }
                autoFocus
                margin="dense"
                label={t("label_current_address")}
                type="address"
                fullWidth
            />
        </Grid>
        <Grid item xs={12}>
            <TextField
                value={message}
                onChange={(e) =>
                    setMessage(e.target.value)
                }
                autoFocus
                margin="dense"
                label={t("label_message")}
                type="text"
                fullWidth
            />

        </Grid>
        <Grid item xs={12}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={lineage}
                        onChange={(e) => setLineage(e.target.checked)}
                    />
                }
                label={t("label_lineage")}
            />
        </Grid>
        <br />
        <Grid item xs={12}>
            <UserSelect label={t("label_spouse")} options={spouseOptions} value={spouseUser} onValueChange={(selected) => setSpouseUser(selected)} />
        </Grid>
        <br />
        {ownedByUsersEnabled && <Grid item xs={12}>
            <UserSelect label={t("label_owned_by")} helperText={t("text_owned_by_help")} is_multiple={true} options={ownedByOptions} value={ownedByUsers} onValueChange={(selected) => setOwnedByUsers(selected)} />
        </Grid>
        }

    </>
}