import { useTranslation } from "react-i18next";
import { MinimalGridToolbar } from "./MinimalGridToolbar"
import { DataGrid } from "@mui/x-data-grid";
import { CustomPagination } from "./Pagination";

export function LocalizedDataGrid({ rows, columns, initialState, onRowClick }) {
    const { t } = useTranslation();

    return <DataGrid
        style={{ height: "100%", width: '100%' }}
        localeText={{
            noRowsLabel: t("noRowsLabel"),
            noResultsOverlayLabel: t("noResultsOverlayLabel"),
            toolbarDensity: t("toolbarDensity"),
            toolbarDensityLabel: t("toolbarDensityLabel"),
            toolbarDensityCompact: t("toolbarDensityCompact"),
            toolbarDensityStandard: t("toolbarDensityStandard"),
            toolbarDensityComfortable: t("toolbarDensityComfortable"),
            toolbarColumns: t("toolbarColumns"),
            toolbarColumnsLabel: t("toolbarColumnsLabel"),
            toolbarFilters: t("toolbarFilters"),
            toolbarFiltersLabel: t("toolbarFiltersLabel"),
            toolbarFiltersTooltipHide: t("toolbarFiltersTooltipHide"),
            toolbarFiltersTooltipShow: t("toolbarFiltersTooltipShow"),
            toolbarFiltersTooltipActive: (count) => t("toolbarFiltersTooltipActive", { count }),
            toolbarQuickFilterPlaceholder: t("toolbarQuickFilterPlaceholder"),
            toolbarQuickFilterLabel: t("toolbarQuickFilterLabel"),
            toolbarQuickFilterDeleteIconLabel: t("toolbarQuickFilterDeleteIconLabel"),
            toolbarExport: t("toolbarExport"),
            toolbarExportLabel: t("toolbarExportLabel"),
            toolbarExportCSV: t("toolbarExportCSV"),
            toolbarExportPrint: t("toolbarExportPrint"),
            toolbarExportExcel: t("toolbarExportExcel"),
            columnsPanelTextFieldLabel: t("columnsPanelTextFieldLabel"),
            columnsPanelTextFieldPlaceholder: t("columnsPanelTextFieldPlaceholder"),
            columnsPanelDragIconLabel: t("columnsPanelDragIconLabel"),
            columnsPanelShowAllButton: t("columnsPanelShowAllButton"),
            columnsPanelHideAllButton: t("columnsPanelHideAllButton"),
            filterPanelAddFilter: t("filterPanelAddFilter"),
            filterPanelRemoveAll: t("filterPanelRemoveAll"),
            filterPanelDeleteIconLabel: t("filterPanelDeleteIconLabel"),
            filterPanelLogicOperator: t("filterPanelLogicOperator"),
            filterPanelOperator: t("filterPanelOperator"),
            filterPanelOperatorAnd: t("filterPanelOperatorAnd"),
            filterPanelOperatorOr: t("filterPanelOperatorOr"),
            filterPanelColumns: t("filterPanelColumns"),
            filterPanelInputLabel: t("filterPanelInputLabel"),
            filterPanelInputPlaceholder: t("filterPanelInputPlaceholder"),
            columnMenuLabel: t("columnMenuLabel"),
            columnMenuShowColumns: t("columnMenuShowColumns"),
            columnMenuManageColumns: t("columnMenuManageColumns"),
            columnMenuFilter: t("columnMenuFilter"),
            columnMenuHideColumn: t("columnMenuHideColumn"),
            columnMenuUnsort: t("columnMenuUnsort"),
            columnMenuSortAsc: t("columnMenuSortAsc"),
            columnMenuSortDesc: t("columnMenuSortDesc"),
            columnHeaderFiltersTooltipActive: (count) => t("columnHeaderFiltersTooltipActive", { count }),
            columnHeaderFiltersLabel: t("columnHeaderFiltersLabel"),
            columnHeaderSortIconLabel: t("columnHeaderSortIconLabel"),
            footerRowSelected: (count) => t("footerRowSelected", { count }),
            footerTotalRows: t("footerTotalRows"),
            footerTotalVisibleRows: (visibleCount, totalCount) => t("footerTotalVisibleRows", { visibleCount, totalCount }),
            checkboxSelectionHeaderName: t("checkboxSelectionHeaderName"),
            checkboxSelectionSelectAllRows: t("checkboxSelectionSelectAllRows"),
            checkboxSelectionUnselectAllRows: t("checkboxSelectionUnselectAllRows"),
            checkboxSelectionSelectRow: t("checkboxSelectionSelectRow"),
            checkboxSelectionUnselectRow: t("checkboxSelectionUnselectRow"),
            booleanCellTrueLabel: t("booleanCellTrueLabel"),
            booleanCellFalseLabel: t("booleanCellFalseLabel"),
            actionsCellMore: t("actionsCellMore"),
            pinToLeft: t("pinToLeft"),
            pinToRight: t("pinToRight"),
            unpin: t("unpin"),
            treeDataGroupingHeaderName: t("treeDataGroupingHeaderName"),
            treeDataExpand: t("treeDataExpand"),
            treeDataCollapse: t("treeDataCollapse"),
            groupingColumnHeaderName: t("groupingColumnHeaderName"),
            groupColumn: (name) => t("groupColumn", { name }),
            unGroupColumn: (name) => t("unGroupColumn", { name }),
            detailPanelToggle: t("detailPanelToggle"),
            expandDetailPanel: t("expandDetailPanel"),
            collapseDetailPanel: t("collapseDetailPanel"),
            MuiTablePagination: {},
            rowReorderingHeaderName: t("rowReorderingHeaderName"),
            aggregationMenuItemHeader: t("aggregationMenuItemHeader"),
            aggregationFunctionLabelSum: t("aggregationFunctionLabelSum"),
            aggregationFunctionLabelAvg: t("aggregationFunctionLabelAvg"),
            aggregationFunctionLabelMin: t("aggregationFunctionLabelMin"),
            aggregationFunctionLabelMax: t("aggregationFunctionLabelMax"),
            aggregationFunctionLabelSize: t("aggregationFunctionLabelSize"),
            filterValueAny: t("filterValueAny"),
            filterValueTrue: t("filterValueTrue"),
            filterValueFalse: t("filterValueFalse")
        }}
        getRowHeight={() => 'auto'}
        slots={{
            toolbar: MinimalGridToolbar,
            pagination: CustomPagination
        }}
        slotProps={{
            toolbar: {
                exampleArg: false
            }
        }}
        rows={rows}
        columns={columns}
        initialState={initialState}
        onRowClick={(params, event, details) => {
            onRowClick(params.row)
        }}
    />
}