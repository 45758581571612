import { Link as MLink } from '@mui/material';
import Typography from '@mui/material/Typography';

export function CopyrightFooter(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <MLink color="inherit" target='_blank' href="https://pitampoudel.com.np/">
                Pitam Poudel
            </MLink>{' '}
            {new Date().getFullYear()}
        </Typography>
    );
}