import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cropToSquare } from '../../utils/PhotoCropper';
import { UserForm } from '../../components/input/UserForm';
import { updateUser, upload } from '../../services/ApiService';


export  function UpdateUserDialog({ open, onClose, setLoading, setAlertError, setAlertMessage, currentUser, user, users, onUpdated }) {
    const { t } = useTranslation();
    const isUpdate = user ? true : false;
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [photoBlobName, setPhotoBlobName] = useState("");
    const [displayPhotoUrl, setDisplayPhotoUrl] = useState("");
    const [dob, setDob] = useState("");
    const [currentAddress, setCurrentAddress] = useState("");
    const [occupation, setOccupation] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [gender, setGender] = useState("");
    const [message, setMessage] = useState("");
    const [parentName, setParentName] = useState("");
    const [spouseUser, setSpouseUser] = useState(null);
    const [ownedByUsers, setOwnedByUsers] = useState([]);
    const [lineage, setLineage] = useState(false);

    React.useEffect(() => {
        setFirstName(user?.firstName ?? "");
        setLastName(user?.lastName ?? "");
        setEmail(user?.email ?? "");
        setPhotoBlobName(user?.photoBlobName ?? "");
        setDisplayPhotoUrl(user?.photoUrl ?? "");
        setDob(user?.dob ?? "");
        setCurrentAddress(user?.currentAddress ?? "");
        setOccupation(user?.occupation ?? "");
        setPhoneNumber(user?.phoneNumber ?? "");
        setGender(user?.gender ?? "");
        setMessage(user?.message ?? "");
        setParentName(user?.parentName ?? "");
        setLineage(user?.lineage ?? false);
        setSpouseUser(users?.find((it) => it._id === user?.spouse) ?? null);
        if (users) {
            if (currentUser) {
                setOwnedByUsers(isUpdate ? user.ownedBy.map((owner) => users?.find((it) => it._id === owner))?.filter(item => item !== null) : [currentUser])
            }
        }
    }, [users, isUpdate, currentUser, user]);


    async function handlePhotoFileChange(file) {
        try {
            const squareImage = await cropToSquare(file);
            const response = await upload("profile", squareImage);
            setPhotoBlobName(response.blobName);
            setDisplayPhotoUrl(response.url)
        } catch (e) {
            console.error(e);
            setAlertError(e.message);
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = {
            id: user?._id,
            firstName,
            lastName,
            email: email.length > 0 ? email : null,
            photoBlobName: photoBlobName.length > 0 ? photoBlobName : null,
            dob: dob.length > 0 ? dob : null,
            currentAddress: currentAddress.length > 0 ? currentAddress : null,
            phoneNumber: phoneNumber.length > 0 ? phoneNumber : null,
            message: message.length > 0 ? message : null,
            parentName: parentName.length > 0 ? parentName : null,
            occupation: occupation.length > 0 ? occupation : null,
            ownedBy: ownedByUsers.map((owner) => owner._id),
            spouse: spouseUser?._id ?? null,
            lineage,
            gender
        };
        setLoading(true);
        try {
            const response = await updateUser(isUpdate, formData);
            setAlertMessage(response.message);
            onUpdated();
            onClose();
        } catch (e) {
            console.error(e);
            setAlertError(e.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{t(isUpdate ? "update_user" : "add_user")}</DialogTitle>
            <DialogContent>
                <UserForm
                    firstName={firstName}
                    setFirstName={setFirstName}
                    lastName={lastName}
                    setLastName={setLastName}
                    email={email}
                    setEmail={setEmail}
                    displayPhotoUrl={displayPhotoUrl}
                    photoBlobName={photoBlobName}
                    handlePhotoFileChange={handlePhotoFileChange}
                    phoneNumber={phoneNumber}
                    setPhoneNumber={setPhoneNumber}
                    dob={dob}
                    setDob={setDob}
                    parentName={parentName}
                    setParentName={setParentName}
                    occupation={occupation}
                    setOccupation={setOccupation}
                    currentAddress={currentAddress}
                    setCurrentAddress={setCurrentAddress}
                    message={message}
                    setMessage={setMessage}
                    ownedByUsersEnabled={true}
                    ownedByOptions={users.filter((it) => it.password)}
                    ownedByUsers={ownedByUsers}
                    setOwnedByUsers={setOwnedByUsers}
                    spouseOptions={users.filter((it) => it.gender !== user?.gender)}
                    spouseUser={spouseUser}
                    setSpouseUser={setSpouseUser}
                    lineage={lineage}
                    setLineage={setLineage}
                    gender={gender}
                    setGender={setGender}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{t("button_cancel")}</Button>
                <Button onClick={handleSubmit}>{t(isUpdate ? "button_update" : "button_add")}</Button>
            </DialogActions>
        </Dialog>

    );
}
