import React, { useState } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import {
    Typography,
    Button,
    Grid,
    Container,
    TextField,
} from '@mui/material';
import { PASSWORD_REGEX_EXP } from '../../utils/Constants';
import { useTranslation } from 'react-i18next';
import { changePassword } from '../../services/ApiService';

const ChangePassword = ({ setLoading, setError, setMessage, onSuccess }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [searchParams] = useSearchParams();

    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isPasswordValid, setIsPasswordValid] = useState(true);

    const handleNewPasswordChange = (value) => {
        setIsPasswordValid(value === "" || PASSWORD_REGEX_EXP.test(value));
        setNewPassword(value);
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = {
            newPassword,
            confirmPassword
        };

        try {
            setLoading(true);
            const response = await changePassword(searchParams.get("token"), formData);
            setMessage(response.message);
            onSuccess();
            navigate('/');
        } catch (e) {
            console.error(e);
            setError(e.message);
        } finally {
            setLoading(false);

        }

    };

    return (
        <Container sx={{ p: 5 }}>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5">{t("change_password")}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            helperText={!isPasswordValid ? t("text_password_not_strong") : ""}
                            error={!isPasswordValid}
                            label={t("label_new_password")}
                            variant="outlined"
                            margin='normal'
                            type='password'
                            fullWidth
                            value={newPassword}
                            onChange={(e) => handleNewPasswordChange(e.target.value)}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label={t("label_confirm_password")}
                            variant="outlined"
                            margin='normal'
                            type='password'
                            fullWidth
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Button disabled={!isPasswordValid} type="submit" variant="contained" color="primary">
                            {t("button_submit")}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Container>
    );
};

export default ChangePassword;
