import { Box, Button, Container, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function PageNotFound() {
    const { t } = useTranslation();
    return (
        <Container
            maxWidth="sm"
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
            }}
        >
            <Box m={5}>
                <Typography variant="h1" sx={{ fontSize: '7.5em', fontWeight: 'bold' }}>
                    404
                </Typography>
                <Typography variant="h3" fontWeight="bold">
                    {t("title_404")}
                </Typography>
                <Typography variant="body1">
                    {t("description_404")}
                </Typography>
                <Button
                    variant="outlined"
                    component={Link}
                    to="/"
                    color="primary"
                    sx={{ mt: 2 }}
                >
                    {t("button_home")}
                </Button>
            </Box>
        </Container>
    );
}

export default PageNotFound;