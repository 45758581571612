import { useTranslation } from "react-i18next";

export function FieldValue({ label, value }) {
    const { t } = useTranslation();

    return <>
        {label + ": "} <span style={{ color: value ? 'inherit' : 'red' }}>
            {value ?? t("value_not_set")}
        </span>
    </>
}