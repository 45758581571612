import React from 'react';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
    IconButton,
    ListItem,
    ListItemText,
    ListItemIcon,
    List
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import CakeIcon from '@mui/icons-material/Cake';
import WorkIcon from '@mui/icons-material/Work';
import { AccessTime, FamilyRestroom, LocationOn, Man, Update } from '@mui/icons-material';
import Person from '@mui/icons-material/Person';
import { useTranslation } from 'react-i18next';
import { formatToNepaliDateTime } from '../../utils/DateTimeUtils';
import { UserPhoto } from "../../components/image/UserPhoto"
import { FieldValue } from "../../components/text/FieldValue"


export const ViewUserDialog = ({ user, owners, userById, onClose }) => {
    const { t } = useTranslation();
    return (
        <>
            <Dialog open={user != null} onClose={onClose} maxWidth="sm" fullWidth>
                <DialogTitle>
                    {t("profile")}
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 16 }}>
                        <UserPhoto
                            photoUrl={user?.photoUrl}
                            firstName={user?.firstName}
                            lastName={user?.lastName}
                            ownedByIconEnabled={user?.ownedBy?.length > 0}
                            verifiedIconEnabled={user?.password}
                            width={90}
                            height={90}
                        ></UserPhoto>
                    </div>
                    <Typography gutterBottom variant="h6" align="center">
                        {`${user?.firstName} ${user?.lastName}`}
                    </Typography>
                    {user?.message && <Typography variant="body1" style={{ color: 'red', align: "center" }} gutterBottom align="center">
                        {user.message}
                    </Typography>
                    }

                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <EmailIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary={<FieldValue label={t("label_email")} value={user?.email} />} />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <PhoneIcon color="secondary" />
                            </ListItemIcon>
                            <ListItemText primary={<FieldValue label={t("label_phone_number")} value={user?.phoneNumber} />} />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CakeIcon sx={{ color: '#ff4081' }} />
                            </ListItemIcon>
                            <ListItemText primary={< FieldValue label={t("label_dob")} value={user?.dob} />} />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <Person sx={{ color: 'green' }} />
                            </ListItemIcon>
                            <ListItemText primary={< FieldValue label={t("label_parent_name")} value={user?.parentName} />} />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <Person sx={{ color: 'green' }} />
                            </ListItemIcon>
                            <ListItemText primary={< FieldValue label={t("label_spouse")} value={userById(user?.spouse)?.firstName} />} />
                        </ListItem>
                        <ListItem sx={{ justifyContent: 'center' }}>
                            <ListItemIcon>
                                <Person sx={{ color: 'green' }} />
                            </ListItemIcon>
                            <ListItemText primary={< FieldValue label={t("label_owned_by")} value={(owners()?.map((owner) => owner.firstName))} />} />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <WorkIcon sx={{ color: 'orange' }} />
                            </ListItemIcon>
                            <ListItemText primary={< FieldValue label={t("label_occupation")} value={user?.occupation} />} />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <LocationOn sx={{ color: 'blue' }} />
                            </ListItemIcon>
                            <ListItemText primary={< FieldValue label={t("label_current_address")} value={user?.currentAddress} />} />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <Man color="primary" />
                            </ListItemIcon>
                            <ListItemText primary={t("label_gender") + ": " + (user?.gender === "MALE" ? t("male") : (user?.gender === "FEMALE" ? t("female") : ""))} />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <FamilyRestroom sx={{ color: 'purple' }} />
                            </ListItemIcon>
                            <ListItemText primary={t("label_lineage") + ": " + (user?.lineage ? t("yes") : t("no"))} />
                        </ListItem>
                        <ListItem sx={{ justifyContent: 'center' }}>
                            <ListItemIcon>
                                <AccessTime sx={{ color: 'grey' }} />
                            </ListItemIcon>
                            <ListItemText primary={t("label_joined_at") + ": " + formatToNepaliDateTime(user?.createdAt)} />
                        </ListItem>
                        <ListItem sx={{ justifyContent: 'center' }}>
                            <ListItemIcon>
                                <Update sx={{ color: 'teal' }} />
                            </ListItemIcon>
                            <ListItemText primary={t("label_updated_at") + ": " + formatToNepaliDateTime(user?.updatedAt)} />
                        </ListItem>
                    </List>
                </DialogContent>
            </Dialog>
        </>

    )
}
