import { Avatar, Badge, AvatarGroup } from '@mui/material';
import { deepOrange } from '@mui/material/colors';
import { SupervisedUserCircle, Verified } from '@mui/icons-material';
import { SmallAvatar } from "./SmallAvatar"

export function UserPhoto({ photoUrl, firstName, lastName, ownedByIconEnabled, verifiedIconEnabled, height, width }) {
    return <Badge
        sx={{ my: 1 }}
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        badgeContent={
            <>
                <AvatarGroup max={3}>
                    {ownedByIconEnabled && <SmallAvatar><SupervisedUserCircle style={{ fontSize: 12 }} color="primary" /></SmallAvatar>}
                    {verifiedIconEnabled && <SmallAvatar><Verified style={{ fontSize: 12 }} color="primary" /></SmallAvatar>}
                </AvatarGroup>

            </>
        }
    >
        <Avatar sx={{ bgcolor: deepOrange[500], height: height, width: width }} alt={firstName + " " + lastName} src={photoUrl} >{firstName?.[0]}</Avatar>
    </Badge>
}