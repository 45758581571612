import { getPersistedToken } from "../App";

const BASE_URL =
    "https://family-folio-uqmk67kmaa-uc.a.run.app"
//  "http://127.0.0.1:8080"

const END_POINT_CREATE_USER = BASE_URL + "/createUser"
const END_POINT_UPDATE_USER = BASE_URL + "/updateUser"
const END_POINT_DELETE_USER = BASE_URL + "/deleteUser"
const END_POINT_USERS = BASE_URL + "/users"
const END_POINT_TOKEN = BASE_URL + "/token"
const END_POINT_RESET_PASSWORD = BASE_URL + "/resetPassword"
const END_POINT_CHANGE_PASSWORD = BASE_URL + "/changePassword"
const END_POINT_PROFILE = BASE_URL + "/profile"
const END_POINT_FILES = BASE_URL + "/files"

async function apiFetch(url, method, body, token) {
    const response = await fetch(url, {
        method: method,
        headers: {
            'Content-Type': "application/json",
            "Authorization": "Bearer " + token
        },
        body: body,
    });

    const responseData = await response.json();

    if (!response.ok) {
        throw new Error(responseData.message);
    }

    return responseData;
}

export async function upload(name, file) {
    const formData = new FormData();
    formData.append(
        name,
        file,
        file.name
    );

    const response = await fetch(END_POINT_FILES, {
        method: "POST",
        headers: {
            "Authorization": "Bearer " + getPersistedToken()
        },
        body: formData
    });

    const responseData = await response.json();

    if (!response.ok) {
        throw new Error(responseData.message);
    }

    return responseData

}

export async function changePassword(token, formData) {
    return apiFetch(END_POINT_CHANGE_PASSWORD, 'POST', JSON.stringify(formData), token ?? getPersistedToken());
}

export async function getMyProfile() {
    return apiFetch(END_POINT_PROFILE, 'GET', null, getPersistedToken());
}

export async function getAllUsers() {
    return apiFetch(END_POINT_USERS, 'GET', null, getPersistedToken());
}

export async function deleteUser(id) {
    return apiFetch(END_POINT_DELETE_USER + "/" + id, 'DELETE', null, getPersistedToken());
}

export async function updateUser(isUpdate, formData) {
    return apiFetch(isUpdate ? END_POINT_UPDATE_USER : END_POINT_CREATE_USER, 'POST', JSON.stringify(formData), getPersistedToken());
}

export async function getToken(formData) {
    return apiFetch(END_POINT_TOKEN, 'POST', JSON.stringify(formData));
}
export async function resetPassword(email) {
    if (email == null) {
        return Error("Email is required");
    }
    const formData = {
        email
    };
    return apiFetch(END_POINT_RESET_PASSWORD, 'POST', JSON.stringify(formData));
}


