import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useTranslation } from 'react-i18next';
import { getToken } from '../../services/ApiService';
import ForgotPasswordDialog from './ForgotPasswordDialog';
import { Link } from '@mui/material';
import { CopyrightFooter } from "../../components/footer/CopyrightFooter"
export default function Login({ setLoading, setError, setMessage, onSuccess }) {
    const { t } = useTranslation();
    const [emailOrPhone, setEmailOrPhone] = useState('');
    const [password, setPassword] = useState('');
    const [isShowingForgotPasswordDialog, setIsShowingForgotPasswordDialog] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = {
            emailOrPhone,
            password
        };

        try {
            setLoading(true);
            const data = await getToken(formData);
            onSuccess(data.accessToken);
            setLoading(false);
        } catch (e) {
            console.error(e);
            setError(e.message);
            setLoading(false);
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    {t("title_login")}
                </Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        label={t("label_email_or_phone")}
                        autoFocus
                        value={emailOrPhone}
                        onChange={(e) => setEmailOrPhone(e.target.value)}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        label={t("label_password")}
                        type="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    > {t("button_login")}
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link onClick={() => setIsShowingForgotPasswordDialog(true)} href="#" variant="body2">
                                {t("forgot_password_")}
                            </Link>
                            <ForgotPasswordDialog open={isShowingForgotPasswordDialog} onClose={() => setIsShowingForgotPasswordDialog(false)} setLoading={setLoading} setError={setError} setMessage={setMessage} />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <CopyrightFooter sx={{ mt: 8, mb: 4 }} />
        </Container>
    );
}