import React, { useEffect } from 'react';
import { Typography, Grid, Container, Button } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cropToSquare } from '../../utils/PhotoCropper';
import { UserForm } from '../../components/input/UserForm';
import { updateUser, upload } from '../../services/ApiService';
import { nullIfEmpty } from '../../utils/Strings';

const UserProfile = ({ currentUser, spouseOptions, setLoading, setAlertMessage, setAlertError, onUpdated }) => {
    const { t } = useTranslation();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [photoBlobName, setPhotoBlobName] = useState('');
    const [displayPhotoUrl, setDisplayPhotoUrl] = useState('');
    const [dob, setDob] = useState('');
    const [currentAddress, setCurrentAddress] = useState('');
    const [occupation, setOccupation] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [gender, setGender] = useState('');
    const [message, setMessage] = useState('');
    const [parentName, setParentName] = useState('');
    const [lineage, setLineage] = useState(false);
    const [spouseUser, setSpouseUser] = useState(null);

    useEffect(() => {
        setFirstName(currentUser?.firstName);
        setLastName(currentUser?.lastName);
        setEmail(currentUser?.email);
        setPhotoBlobName(currentUser?.photoBlobName ?? "");
        setDisplayPhotoUrl(currentUser?.photoUrl ?? "");
        setDob(currentUser?.dob);
        setCurrentAddress(currentUser?.currentAddress ?? "");
        setOccupation(currentUser?.occupation ?? "");
        setPhoneNumber(currentUser?.phoneNumber);
        setGender(currentUser?.gender);
        setMessage(currentUser?.message ?? "");
        setParentName(currentUser?.parentName ?? "");
        setLineage(currentUser?.lineage ?? false);
        setSpouseUser(spouseOptions?.find((it) => it._id === currentUser?.spouse) ?? null);
    }, [currentUser, spouseOptions]);

    async function handlePhotoFileChange(file) {
        try {
            const squareImage = await cropToSquare(file);
            const response = await upload("profile", squareImage);
            setPhotoBlobName(response.blobName);
            setDisplayPhotoUrl(response.url)
        } catch (e) {
            console.error(e);
            setAlertError(e.message);
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = {
            id: currentUser?._id,
            firstName,
            lastName,
            email: nullIfEmpty(email),
            photoBlobName: nullIfEmpty(photoBlobName),
            dob: nullIfEmpty(dob),
            currentAddress: nullIfEmpty(currentAddress),
            phoneNumber: nullIfEmpty(phoneNumber),
            message: nullIfEmpty(message),
            parentName: nullIfEmpty(parentName),
            occupation: nullIfEmpty(occupation),
            ownedBy: [],
            lineage,
            gender,
            spouse: spouseUser?._id ?? null,
        };

        try {
            setLoading(true);
            const response = await updateUser(true, formData);
            setAlertMessage(response.message);
            onUpdated();
        } catch (e) {
            console.error(e);
            setAlertError(e.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container sx={{ p: 5 }}>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5">{t("profile")}</Typography>
                    </Grid>

                    <UserForm firstName={firstName}
                        setFirstName={setFirstName}
                        lastName={lastName}
                        setLastName={setLastName}
                        email={email}
                        setEmail={setEmail}
                        displayPhotoUrl={displayPhotoUrl}
                        photoBlobName={photoBlobName}
                        handlePhotoFileChange={handlePhotoFileChange}
                        phoneNumber={phoneNumber}
                        setPhoneNumber={setPhoneNumber}
                        gender={gender}
                        setGender={setGender}
                        dob={dob}
                        setDob={setDob}
                        parentName={parentName}
                        setParentName={setParentName}
                        occupation={occupation}
                        setOccupation={setOccupation}
                        currentAddress={currentAddress}
                        setCurrentAddress={setCurrentAddress}
                        messageEnabled={true}
                        message={message}
                        setMessage={setMessage}
                        ownedByUserEnabled={false}
                        lineage={lineage}
                        setLineage={setLineage}
                        spouseOptions={spouseOptions}
                        spouseUser={spouseUser}
                        setSpouseUser={setSpouseUser}
                    />
                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" color="primary">
                            {t("button_update")}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Container>


    );
};

export default UserProfile;