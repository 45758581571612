import { Route, Routes, useNavigate } from "react-router-dom";
import UsersTable from "./pages/datagrid/UsersTable";
import ChangePassword from "./pages/common/ChangePassword";
import PageNotFound from "./pages/common/404";
import Profile from './pages/loggedin/Profile';
import { useTranslation } from "react-i18next";
import {TopAppBar} from "./components/appbar/TopAppBar"

export function LoggedInApp({ setLoading, setError, setMessage, profile, fetchProfile, logout, users, fetchUsers }) {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return <div>
        <TopAppBar pages={[
            // {
            //     title: 'ADD', onClick: () => {
            //         handleCloseNavMenu()

            //     }
            // },
        ]}
            settings={[
                {
                    title: t('profile'), onClick: () => {

                        navigate('/profile')
                    }
                },
                {
                    title: t('change_password'), onClick: () => {

                        navigate('/change_password')

                    }
                },
                {
                    title: t('logout'), onClick: () => {

                        navigate('/')
                        logout()
                    }
                }
            ]}
            user={profile} />
        <Routes>
            <Route exact path='/' element={
                <UsersTable
                    setError={setError}
                    setMessage={setMessage}
                    setLoading={setLoading}
                    users={users}
                    fetchUsers={() => fetchUsers()}
                    fetchProfile={() => fetchProfile()}
                    currentUser={profile} />
            }
            />
            <Route exact path='/profile' element={
                <Profile
                    currentUser={profile}
                    spouseOptions={users?.filter((it) => it.gender !== profile?.gender)}
                    setAlertError={setError}
                    setAlertMessage={setMessage}
                    setLoading={setLoading}
                    onUpdated={() => fetchProfile()} />
            }
            />
            <Route exact path='/change_password' element={
                <ChangePassword
                    setError={setError}
                    setMessage={setMessage}
                    setLoading={setLoading}
                    logout={logout} />
            }
            />

            <Route path="*" element={
                <PageNotFound />
            }
            />
        </Routes>
    </div>
}