import { Route, Routes } from "react-router-dom";
import PageNotFound from "./pages/common/404";
import ChangePassword from "./pages/common/ChangePassword";
import Login from "./pages/prelogin/Login";

export function PreLoginApp({ setLoading, setError, setMessage, onLoginSuccess }) {
    return <Routes>
        <Route exact path='/' element={
            <Login
                onSuccess={onLoginSuccess}
                setLoading={setLoading}
                setError={setError}
                setMessage={setMessage} />
        } />
        <Route exact path='/change_password' element={
            <ChangePassword
                setLoading={setLoading}
                setMessage={setMessage}
                setError={setError}
                onSuccess={() => { }} />
        } />
        <Route path="*" element={
            <PageNotFound />
        } />
    </Routes>
}