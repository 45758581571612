import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { UserPhoto } from "../image/UserPhoto"
export default function UserSelect({ label, helperText, is_multiple, options, value, onValueChange }) {
    return (
        <Autocomplete
            multiple={is_multiple}
            value={value}
            options={options}
            isOptionEqualToValue={(user1, value) => user1._id === value?._id}
            onChange={(event, newValue) => {
                onValueChange(newValue);
            }}
            getOptionLabel={(option) => option.firstName + " " + option.lastName}
            renderOption={(props, option) => (
                <Box component="li"  {...props}>
                    <div style={{ marginRight: '8px' }}>
                        <UserPhoto
                            photoUrl={option?.photoUrl}
                            firstName={option?.firstName}
                            lastName={option?.lastName}
                            ownedByIconEnabled={option?.ownedBy?.length > 0}
                            verifiedIconEnabled={option?.password}
                        ></UserPhoto>
                    </div>
                    {option.firstName + " " + option.lastName}
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    helperText={helperText}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                />
            )}

        />
    );
}