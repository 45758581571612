import NepaliDate from "nepali-date-converter";

export const formatToNepaliDateTime = (utcDateString) => {
    if (utcDateString == null) return null;
    const date = new Date(utcDateString);
    const nepaliDate = NepaliDate.fromAD(date).format('DD MMMM YYYY', 'en');
    const time = date.toLocaleTimeString(undefined, {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    });
    return nepaliDate + " " + time;

};
