import { GridCsvExportMenuItem, GridPrintExportMenuItem, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExportContainer, GridToolbarFilterButton, GridToolbarQuickFilter } from "@mui/x-data-grid";


function CustomExportButton(props) {
    return (
        <GridToolbarExportContainer {...props}>
            <GridCsvExportMenuItem />
            <GridPrintExportMenuItem />
        </GridToolbarExportContainer>
    );
}

export const MinimalGridToolbar = () => {
    return (
        <GridToolbarContainer>
            <CustomExportButton printOptions={{
                hideFooter: true,
                hideToolbar: true,
            }} />
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarQuickFilter />
        </GridToolbarContainer>
    );
};