import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';


const resources = {
    "ne": {
        "translation": {
            "app_name": "GIC पौडेल परिवार लिखुतामाकोशी २, सैपुबेशी (गङ्गा, ईन्द्र, चन्द्र)",
            "title_login": "लग इन",
            "label_email": "ईमेल ठेगाना",
            "label_email_or_phone": "ईमेल ठेगाना वा फोन नम्बर",
            "label_password": "पासवर्ड",
            "button_login": "लगइन गर्नुहोस्",
            "forgot_password_": "पासवर्ड बिर्सिए?",
            "explanation_enter_email_to_reset_password": "तपाईंको पासवर्ड रिसेट गर्नका लागि कृपया तपाईंको ईमेल ठेगाना यहाँ प्रवेश गर्नुहोस्। हामी तपाईंलाई पासवर्ड रिसेट गर्ने लिंक पठाउँछौं।",
            "button_cancel": "रद्द गर्नुहोस्",
            "button_delete": "मेटाउनुहोस्",
            "button_reset": "रिसेट गर्नुहोस्",
            "fetching_profile": "प्रोफाइल प्राप्त गर्दै...",
            "profile": "प्रोफाइल",
            "label_first_name": "पहिलो नाम",
            "label_last_name": "थर",
            "label_photo": "फोटो",
            "label_dob": "जन्म मिति",
            "label_current_address": "हालको ठेगाना",
            "label_occupation": "पेशा",
            "label_phone_number": "फोन नम्बर",
            "label_message": "संदेश",
            "label_parent_name": "माता-पिताको नाम",
            "label_owned_by": "यूजर नियन्त्रक",
            "text_owned_by_help": "बच्चा/वृद्ध को मामला मा",
            "label_lineage": "पौडेल वंश",
            "label_gender": "लैंगिकता",
            "label_spouse": "पति/पत्नी",
            "label_joined_at": "सामेल गरिएको मिति",
            "label_updated_at": "परिमार्जन गरिएको मिति",
            "male": "पुरुष",
            "female": "महिला",
            "button_update": "अपडेट गर्नुहोस्",
            "change_password": "पासवर्ड परिवर्तन गर्नुहोस्",
            "label_new_password": "नयाँ पासवर्ड",
            "label_confirm_password": "पासवर्ड पुष्टि गर्नुहोस्",
            "text_password_not_strong": "पासवर्डमा १ देखि ९सम्मको अंक, एक छोटो अक्षर, एक ठूलो अक्षर, एक विशेष वर्ण, कुनै खाली स्थान नरहेको र ९-१६ अक्षरको हुनुपर्छ",
            "button_submit": "पेश गर्नुहोस्",
            "logout": "लग आउट",
            "title_404": "उहो! तपाईं बिग्रिएका छौं",
            "description_404": "तपाईंले खोज्दै गरेको पृष्ठ अब अवस्थित छैन। तपाईं यहाँ कसरी पुगे हो भन्दै एक रहस्य हो। तर तपाईं गर्दा होमपेजमा फर्किनका लागि तलका बटनमा क्लिक गर्न सक्दैन।",
            "button_home": "होम",
            "add_user": "प्रयोगकर्ता थप्नुहोस्",
            "update_user": "प्रयोगकर्ता परिमार्जन गर्नुहोस्",
            "button_add": "थप्नुहोस्",
            "sn": "क्र.सं.",
            "fullName": "पुरा नाम",
            "label_edit": "संशोधन/अपडेट",
            "text_age_value": "{{age}} वर्ष",
            "value_not_set": "छैन",
            "yes": "हो",
            "no": "होइन",
            "delete_user": "प्रयोगकर्ता मेटाउनुहोस्",
            "are_you_sure_to_delete_user_": "के तपाइँ निश्चित हुनुहुन्छ कि तपाइँ {{name}} मेटाउन चाहनुहुन्छ?",

            // MUI DATA GRID
            "noRowsLabel": "कुनै पंक्ति पाइएन",
            "noResultsOverlayLabel": "कुनै परिणाम प्राप्त गरिएन।",
            "toolbarDensity": "घनत्व",
            "toolbarDensityLabel": "घनत्व",
            "toolbarDensityCompact": "संक्षेप",
            "toolbarDensityStandard": "मानक",
            "toolbarDensityComfortable": "आरामदायक",
            "toolbarColumns": "स्तंभ",
            "toolbarColumnsLabel": "स्तंभ चयन गर्नुहोस्",
            "toolbarFilters": "फिल्टरहरू",
            "toolbarFiltersLabel": "फिल्टरहरू देखाउनुहोस्",
            "toolbarFiltersTooltipHide": "फिल्टरहरू लुकाउनुहोस्",
            "toolbarFiltersTooltipShow": "फिल्टरहरू देखाउनुहोस्",
            "toolbarFiltersTooltipActive": (count) =>
                count !== 1 ? `${count} सक्रिय फिल्टरहरू` : `${count} सक्रिय फिल्टर`,
            "toolbarQuickFilterPlaceholder": "खोजी गर्नुहोस्...",
            "toolbarQuickFilterLabel": "खोजी",
            "toolbarQuickFilterDeleteIconLabel": "मेटाउनुहोस्",
            "toolbarExport": "निर्यात गर्नुहोस्",
            "toolbarExportLabel": "निर्यात गर्नुहोस्",
            "toolbarExportCSV": "CSV रूपमा डाउनलोड गर्नुहोस्",
            "toolbarExportPrint": "प्रिन्ट गर्नुहोस्",
            "toolbarExportExcel": "Excel रूपमा डाउनलोड गर्नुहोस्",
            "columnsPanelTextFieldLabel": "स्तंभ प्राप्त गर्नुहोस्",
            "columnsPanelTextFieldPlaceholder": "स्तंभ शीर्षक",
            "columnsPanelDragIconLabel": "स्तंभ पुन: क्रमण गर्नुहोस्",
            "columnsPanelShowAllButton": "सबै देखाउनुहोस्",
            "columnsPanelHideAllButton": "सबै लुकाउनुहोस्",
            "filterPanelAddFilter": "फिल्टर थप्नुहोस्",
            "filterPanelRemoveAll": "सबै हटाउनुहोस्",
            "filterPanelDeleteIconLabel": "मेटाउनुहोस्",
            "filterPanelLogicOperator": "तार्किक अपरेटर",
            "filterPanelOperator": "अपरेटर",
            "filterPanelOperatorAnd": "तथा",
            "filterPanelOperatorOr": "वा",
            "filterPanelColumns": "स्तंभहरू",
            "filterPanelInputLabel": "मूल्य",
            "filterPanelInputPlaceholder": "फिल्टर मूल्य",
            "columnMenuLabel": "मेनू",
            "columnMenuShowColumns": "स्तंभहरू देखाउनुहोस्",
            "columnMenuManageColumns": "स्तंभ प्रबन्धन गर्नुहोस्",
            "columnMenuFilter": "फिल्टर",
            "columnMenuHideColumn": "स्तंभ लुकाउनुहोस्",
            "columnMenuUnsort": "अद्वितीय गर्नुहोस्",
            "columnMenuSortAsc": "आ-श मा गोर्नुहोस्",
            "columnMenuSortDesc": "श-आ मा गोर्नुहोस्",
            "columnHeaderFiltersTooltipActive": (count) =>
                count !== 1 ? `${count} सक्रिय फिल्टरहरू` : `${count} सक्रिय फिल्टर`,
            "columnHeaderFiltersLabel": "फिल्टरहरू देखाउनुहोस्",
            "columnHeaderSortIconLabel": "गोर्नुहोस्",
            "footerRowSelected": (count) =>
                count !== 1
                    ? `${count.toLocaleString()} पंक्तिहरू चयन गरिएका`
                    : `${count.toLocaleString()} पंक्ति चयन गरिएको`,
            "footerTotalRows": "कुल पंक्तिहरू:",
            "footerTotalVisibleRows": (visibleCount, totalCount) =>
                `${visibleCount.toLocaleString()} मा बाट ${totalCount.toLocaleString()}`
        }
    }
}

i18n
    .use(initReactI18next)
    .init({
        lng: "ne",
        fallbackLng: "en",
        interpolation: {
            escapeValue: false,
        },
        resources: resources
    });

export default i18n;
