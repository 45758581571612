import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { deleteUser } from '../../services/ApiService';

export const DeleteUserDialog = ({ user, open, handleClose, setLoading, setMessage, setError }) => {
    const { t } = useTranslation();

    const handleConfirmDeleteUser = async () => {
        setLoading(true);
        try {
            const response = await deleteUser(user._id);
            setMessage(response.message);
            handleClose();
        } catch (e) {
            console.error(e);
            setError(e.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{t("delete_user")}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t("are_you_sure_to_delete_user_", { name: user?.firstName + " " + user?.lastName })}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {t("button_cancel")}
                </Button>
                <Button onClick={handleConfirmDeleteUser} color="primary">
                    {t("button_delete")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteUserDialog;
